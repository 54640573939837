import React, { useState } from "react";
import EcommerceIcons from "../../assets/EcommerceIcons";
import { useNavigate } from "react-router-dom";
import "./styles/landing.css";
import HeaderText from "../../components/HeaderText/HeaderText";
import PrincipleModal from "../../modals/LandingPageModals/PrincipleModal/PrincipleModal";

const LandingPage = ({ categories }) => {
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [sendPrincipal, setSendPrincipal] = useState();

  const handleSearch = () => {
    navigate(`/all-products?search=${searchQuery}`);
  };

  const featuredProducts = [
    {
      img: EcommerceIcons.Featured,
      name: "Cold Cups",
      price: "6",
      desc: "20 oz (600 ml) Compostable PLA Cold Cups | Clear | BPI Certified",
    },
    {
      img: EcommerceIcons.Featured,
      name: "Cold Cups",
      price: "6",
      desc: "20 oz (600 ml) Compostable PLA Cold Cups | Clear | BPI Certified",
    },
    {
      img: EcommerceIcons.Featured,
      name: "Cold Cups",
      price: "6",
      desc: "20 oz (600 ml) Compostable PLA Cold Cups | Clear | BPI Certified",
    },
    {
      img: EcommerceIcons.Featured,
      name: "Cold Cups",
      price: "6",
      desc: "20 oz (600 ml) Compostable PLA Cold Cups | Clear | BPI Certified",
    },
    {
      img: EcommerceIcons.Featured,
      name: "Cold Cups",
      price: "6",
      desc: "20 oz (600 ml) Compostable PLA Cold Cups | Clear | BPI Certified",
    },
    {
      img: EcommerceIcons.Featured,
      name: "Cold Cups",
      price: "6",
      desc: "20 oz (600 ml) Compostable PLA Cold Cups | Clear | BPI Certified",
    },
  ];

  const cardData = [
    {
      id: 1,
      title: "Map",
      description: " Locate our smart bins on the map",
      image: EcommerceIcons.Howto1,
    },
    {
      id: 2,
      title: "Tap",
      description: "Tap your phone of the bin to unlock the bin",
      image: EcommerceIcons.Howto2,
    },
    {
      id: 3,
      title: "Drop",
      description: "Drop your cans and earn points instantly",
      image: EcommerceIcons.Howto3,
    },
    {
      id: 4,
      title: "Shop",
      description:
        "Use your rewards points to buy cool stuff from the Recall store",
      image: EcommerceIcons.Howto4,
    },
  ];

  const principles = [
    {
      img: EcommerceIcons.Principle1,
      Bg: EcommerceIcons.Principle1Bg,
      title: "Achieving Sustainability through Efficiency.       ",
      desc: "When it comes to saving our planet, we need to act fast and act smart. Some actions can have a greater impact than others. <br/> That’s why at Recall, we decided to focus on the use and re-use of Aluminium first. Here’s why :  ",
      lists: [
        {
          title: "Infinite Recyclability",
          text: "Unlike plastics, which tend to degrade in quality with each recycling cycle, aluminum can be recycled over and over without losing its inherent properties. This means that the same aluminum can be repurposed into new products without any significant loss of quality, reducing the demand for virgin aluminum production, which is an energy-intensive process.",
        },
        {
          title: "Greater Energy Savings",
          text: "Greater Energy Savings : Recycling an aluminium can requires only 5% of the energy required to make new one from scratch. That’s 95% energy savings! This reduction in energy consumption translates to fewer greenhouse gas emissions and a lighter carbon footprint.",
        },
        {
          title: "Reduced Waste",
          text: "Compared to plastics, aluminum recycling produces less waste. Plastics, especially single-use plastics, pose significant challenges in terms of disposal and recycling due to their complex composition. Aluminum, on the other hand, can be easily sorted and recycled, diverting valuable materials from landfills and incinerators.           ",
        },
        {
          title: "Long-lasting Products",
          text: "Aluminum is known for its durability and resistance to corrosion. This quality ensures that products made from recycled aluminum have a longer lifespan, reducing the need for replacements and conserving resources in the long run.",
        },
      ],
    },
    {
      img: EcommerceIcons.Principle2,
      Bg: EcommerceIcons.Principle2Bg,
      title: "Turning Eco-responsibility into a lifestyle choice       ",
      desc: "Eco-friendly practices should be more than just a fleeting trend or an occasional endeavour. Ecoresponsibility needs to be ingrained into our daily lives as a steadfast lifestyle choice and part of our culture. <br/> Recall consciously works to ensure that the activities involved are fun, easily accessible and rewarding. By doing this, we aim to persuade people into gradually adopting recycling and responsible use into their daily lives.<br/> When ecoresponsibility becomes an intrinsic part of our lives, its effects extend beyond individual actions. Friends, family, and acquaintances are influenced by our choices, leading to a ripple effect that gradually expands the circle of eco-conscious individuals. This collective impact holds the potential to drive societal change and influence policy decisions.",
    },
    {
      img: EcommerceIcons.Principle3,
      Bg: EcommerceIcons.Principle3Bg,
      title: "Collaborating towards a Greener Planet       ",
      desc: "We believe that the only way to achieve true sustainability is through collaboration.<br/> The involvement of people, businesses and governments working together towards this common goal, can create a significant impact and is, in fact, the only way to ensure consistent and sustained improvement towards our endeavors to safeguard our planet.<br/><br/>  Involving local businesses from various industries allows for us to close loops in the supply change and transition towards a more circular economy.<br/><br/> Recall aims to facilitate this collaboration by providing a great platform for individuals and businesses to interact, access sustainable products, gain awareness and share their impact.   ",
    },
  ];
  return (
    <div className="recall-store-landing">
      <PrincipleModal
        open={showModal}
        data={sendPrincipal}
        handleClose={() => {
          setShowModal(false);
        }}
      />
      <div id="storeHeader" className="recall-store-landing-section-one">
        <div className="recall-store-landing-section-heading-content">
          <div className="recall-store-landing-section-heading">
            <h1> Make a Difference with Every Purchase from Recall Store!</h1>
          </div>
          <p className="recall-store-landing-section-heading-description">
            Your one stop shop for carefully curated ethical and sustainable
            products.{" "}
          </p>
          <div className="recall-store-landing-section-one-search">
            <form
              action=""
              onSubmit={(e) => {
                e.preventDefault();
                handleSearch();
              }}
            >
              <img src={EcommerceIcons.Search} alt="" />
              <input
                placeholder="Search products"
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <button type="submit" onClick={handleSearch}>
                Search
              </button>
            </form>
          </div>
        </div>
      </div>
      <div
        id="categories"
        className="recall-store-landing-section-two  | max-width"
      >
        <HeaderText size="large" text="Categories" />
        <div className="recall-store-categories-list">
          {/* <div className="recall-store-categories-list-item">
            <div className="recall-store-categories-list-item-img">
              <img src={EcommerceIcons.RecallMerch} alt="" />
            </div>
            <div className="recall-store-categories-list-item-info">
              Recall Merch
              <img src={EcommerceIcons.CategoryArrow} alt="" />
            </div>
          </div> */}

          {/* <div 
            className="recall-store-categories-list-item"
          >
            <div className="recall-store-categories-list-item-img">
              <img src={EcommerceIcons.RecallAccessories} alt="" />
            </div>
            <div className="recall-store-categories-list-item-info">
             
              Accessories
              <img src={EcommerceIcons.CategoryArrow} alt="" />
            </div>
          </div> */}

          {categories?.map((category, index) => {
            return (
              <div
                onClick={() => {
                  navigate(`/categories/${category.name}/${category.id}`);
                }}
                className="recall-store-categories-list-item"
                key={index}
              >
                <div className="recall-store-categories-list-item-img">
                  <img src={category.image} alt="" />
                </div>
                <div className="recall-store-categories-list-item-info">
                  {category.name}
                  <img src={EcommerceIcons.CategoryArrow} alt="" />
                </div>
              </div>
            );
          })}

          <div
            onClick={() => {
              navigate(`/categories`);
            }}
            className="recall-store-categories-list-item  | other-categories"
          >
            <div className="recall-store-categories-list-item-img">
              <img src={EcommerceIcons.Others} alt="" />
            </div>
            <div className="recall-store-categories-list-item-info">
              Others
              <img src={EcommerceIcons.CategoryArrow} alt="" />
            </div>
          </div>
        </div>
        <div
          onClick={() => {
            navigate(`/categories`);
          }}
          className="category-others"
        >
          <img src={EcommerceIcons.OtherBag} alt="" />
          <div className="category-others-text">
            <p>
              View All Categories <br /> in detail
            </p>
            <img src={EcommerceIcons.CategoryArrow} alt="" />
          </div>
        </div>
      </div>
      <div className="recall-store-landing-section-three | max-width">
        <HeaderText size="large" text="Our Impact" />
        <div className="recall-store-landing-section-three-flex">
          <div className="recall-store-landing-section-three-left">
            <img src={EcommerceIcons.ImpactRecall} alt="" />
          </div>
          <div className="recall-store-landing-section-three-right">
            <div className="recall-store-landing-section-heading">
              <h1>
                {" "}
                Turning Waste into <br /> Wonder
              </h1>
            </div>
            <p className="recall-store-landing-section-heading-description">
              At Recall Store, we believe in the power of recycling and
              sustainable living. Our mission is to turn waste into wonder by
              creating high-quality products from recycled aluminum cans. By
              shopping with us, you're not just purchasing eco-friendly
              products, but you're also contributing to a cleaner, greener
              future. With every purchase, you're helping to reduce the amount
              of waste in our landfills, conserve energy, and preserve our
              planet for generations to come. Join us in our mission to make a
              positive impact on the world and choose Recall Store for all your
              sustainable living needs.Contribute to earth’s sustainability by
              recycling soda cans
            </p>
            <button
              onClick={() => {
                navigate("/all-products");
              }}
            >
              Check Out All Products{" "}
              <img src={EcommerceIcons.TextArrow} alt="" />
            </button>
          </div>
        </div>
      </div>
      <div className="recall-store-landing-section-four  | max-width">
        <HeaderText size="large" text="Featured Products" />
        <div className="recall-store-landing-section-four-container">
          <div className="recall-store-landing-section-four-featured">
            {featuredProducts.map((product, index) => {
              return (
                <div
                  key={index}
                  className="recall-store-landing-section-four-featured-item"
                >
                  <div className="recall-store-landing-section-four-featured-item-img">
                    <img src={product.img} alt="" />
                  </div>
                  <div className="recall-store-landing-section-four-featured-item-info">
                    <div className="recall-store-landing-section-four-featured-item-info-name">
                      {product.name}
                    </div>
                    <div className="recall-store-landing-section-four-featured-item-info-price">
                      {product.price}
                      <span>AED/pack</span>
                    </div>
                  </div>
                  <div className="recall-store-landing-section-four-featured-item-desc">
                    {product.desc}
                  </div>
                  <button>
                    Add to Cart{" "}
                    <img src={EcommerceIcons.TextArrowWhite} alt="" />
                  </button>
                </div>
              );
            })}
          </div>
          <div className="recall-store-landing-section-four-large-text">
            Our Best Sellers <img src={EcommerceIcons.CategoryArrow} alt="" />
          </div>
        </div>
      </div>
      <div className="recall-store-landing-section-five  | max-width">
        <HeaderText size="large" text="Here’s How Recall App Works" />
        <div className="recall-landing-page-section-3-cards">
          {cardData.map((card) => {
            return (
              <>
                <div className="recall-landing-page-section-3-card">
                  <div className="number-pill">
                    <div className="number-pill-text">{card.id}</div>
                  </div>
                  <div className="recall-landing-page-section-3-card-img">
                    <img src={card.image} alt="" />
                  </div>
                  <div className="recall-landing-page-section-3-card-text">
                    <div className="recall-landing-page-section-3-card-text-header">
                      {card.title}
                    </div>
                    <div className="recall-landing-page-section-3-card-text-body">
                      {card.description}
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
      <div className="recall-store-landing-section-six">
        <div className="recall-store-landing-section-six-container  | max-width">
          <div className="recall-store-landing-section-six-container-flex">
            <div className="recall-store-landing-section-six-container-flex-left">
              <HeaderText
                size="large"
                text="Here’s How Recall App Works"
                color="white"
              />
              <div className="recall-store-landing-section-six-header">
                Recall Store : Your One-Stop shop for a cleaner tomorrow
              </div>
              <div className="recall-store-landing-section-six-header-description">
                Recycle, Earn Points, Redeem for Earth-friendly Goods. Join the
                Movement towards a Greener Future Today!"
              </div>
              <button
                onClick={() => {
                  navigate("/all-products");
                }}
              >
                Visit Store <img src={EcommerceIcons.TextArrowBlue} alt="" />
              </button>
              <div className="recall-store-landing-section-six-container-flex-img">
                <img src={EcommerceIcons.RecallPromo} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="recall-store-landing-section-seven | max-width">
        <HeaderText size="large" text="Our Core Principles" />
        <div className="recall-landing-page-section-5-cards">
          {principles.map((principle, index) => {
            return (
              <>
                <div
                  key={index}
                  onClick={() => {
                    setSendPrincipal(principle);
                    setShowModal(true);
                  }}
                  className="recall-landing-page-section-5-card"
                >
                  <div className="recall-landing-page-section-5-card-image">
                    <img src={principle.img} alt="" />
                  </div>
                  <div className="recall-landing-page-section-5-card-text">
                    {principle.title}
                    <img
                      className="arrow"
                      src={EcommerceIcons.TextArrow}
                      alt=""
                    />
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </div>
      <div
        id="contactus"
        className="recall-store-landing-section-eight | max-width"
      >
        <HeaderText size="large" text="Contact Us" />
        <div className="contact-us-flex">
          <div className="contact-us-left">
            <div className="input">
              <p>What’s your name?</p>
              <input placeholder="Your Name" type="text" />
            </div>
            <div className="input">
              <p>What’s your email?</p>
              <input placeholder="Your Email" type="text" />
            </div>
            <div className="input">
              <p>What’s your message?</p>
              <textarea placeholder="Your Message" type="text" />
            </div>
            <div className="send-btn">
              <button>Send Message</button>
            </div>
          </div>
          <div className="contact-right">
            <img src={EcommerceIcons.Contact} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
