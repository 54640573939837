import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import EcommerceIcons from "../../assets/EcommerceIcons";
import { useNavigate } from "react-router-dom";
import { getProductsByCategory } from "../../services/Product";
import "./styles/categories.css";
import HeaderText from "../../components/HeaderText/HeaderText";
import Filters from "../../components/Filters/Filters";
import MobileFilters from "../../components/Filters/MobileFilters";

const Categories = ({ categories }) => {
  const { name } = useParams();
  const { id } = useParams();
  const navigate = useNavigate();
  const filtersRef = useRef(null);
  const [products, setProducts] = useState([]);
  const [distinctProducts, setDistinctProducts] = useState([]);
  const [isFixed, setIsFixed] = useState(false);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [filteredSizes, setFilteredSizes] = useState([]);
  const [filteredMaterials, setFilteredMaterials] = useState([]);
  const [filteredTypes, setFilteredTypes] = useState([]);
  //   const [search, setSearch] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getProductsByCategory(id);
        // setFilteredData(response.products);
        setProducts(response?.products);
        console.log(response?.products);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [id]);

  useEffect(() => {
    const handleScroll = () => {
      if (filtersRef.current) {
        const filtersOffsetTop = filtersRef.current.getBoundingClientRect().top;

        if (!isFixed && filtersOffsetTop <= 80) {
          setIsFixed(true);
        } else if (isFixed && filtersOffsetTop > 80) {
          setIsFixed(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isFixed]);
  useEffect(() => {
    // Use a Set to store unique product types
    const uniqueProductTypes = new Set();
    const distinctProductsArray = products.filter((product) => {
      if (!uniqueProductTypes.has(product.product_type)) {
        uniqueProductTypes.add(product.product_type);
        return true;
      }
      return false;
    });

    // Store the distinct products in the state
    setDistinctProducts(distinctProductsArray);
  }, [products]);

  useEffect(() => {
    // document.title = pageTitle;
    document.title = `${name}`;
    return () => {
      document.title = "Recall Store | The Sustainable Solution";
    };
  }, [name]);

  return (
    <div className="recall-categories-page">
      <div className="recall-store-banner">
        <div className="recall-store-banner-content">
          <div className="recall-store-banner-breadcrumb">
            <p
              onClick={() => {
                navigate("/");
              }}
            >
              {" "}
              Categories
            </p>{" "}
            <img src={EcommerceIcons.LinkArrow} alt="" /> <p>{name}</p>
          </div>
          <div className="recall-store-banner-search | categ">
            <h1>{name}</h1>
            <div className="recall-store-landing-section-one-search | recall-store-landing-section-one-search-category">
              <img src={EcommerceIcons.Search} alt="" />
              <input placeholder="Search products" type="text" />
              <button>Submit</button>
            </div>
          </div>
        </div>
      </div>
      <div className="recall-categories-page-content">
        <HeaderText size="large" text="Products" />
        <div className="recall-categories-page-content-desc">
          <p>Eco-Friendly | Compostable | Variety Collection</p>
        </div>
        <div className="distinct-products">
          {distinctProducts?.map((product, index) => {
            console.log("distinct products", product);
            return (
              <div
                onClick={() => {
                  navigate(
                    `/subcategories/${name}/${id}/${product.product_type}`
                  );
                }}
                className="recall-store-categories-list-item"
                key={index}
              >
                <div className="recall-store-categories-list-item-img">
                  {categories && <img src={categories[1]?.image} alt="" />}
                </div>
                <div className="recall-store-categories-list-item-info">
                  {product?.product_type}
                  <img src={EcommerceIcons.CategoryArrow} alt="" />
                </div>
              </div>
            );
          })}
        </div>

        <MobileFilters
          categories={categories}
          filteredData={products}
          setFilteredData={setProducts}
          filteredSizes={filteredSizes}
          setFilteredSizes={setFilteredSizes}
          filteredMaterials={filteredMaterials}
          setFilteredMaterials={setFilteredMaterials}
          filteredTypes={filteredTypes}
          setFilteredTypes={setFilteredTypes}
        />

        <div
          ref={filtersRef}
          className={`filters-wrap ${isFixed ? "sticky" : ""}`}
        >
          <Filters
            categories={categories}
            filteredData={products}
            setFilteredData={setProducts}
            isFixed={isFixed}
            filteredCategories={filteredCategories}
            setFilteredCategories={setFilteredCategories}
            filteredSizes={filteredSizes}
            setFilteredSizes={setFilteredSizes}
            filteredMaterials={filteredMaterials}
            setFilteredMaterials={setFilteredMaterials}
            filteredTypes={filteredTypes}
            setFilteredTypes={setFilteredTypes}
          />
        </div>
        <HeaderText size="large" text="Products in Detail" />
        <div className="category-products">
          {products?.map((product, index) => {
            return (
              <div key={index} className="category-product">
                <div className="category-product-img">
                  <img src={EcommerceIcons.Featured} alt="" />
                </div>
                <div className="category-product-details">
                  <div className="category-product-name-price">
                    <div className="category-product-name">
                      {product.product_type}
                    </div>
                    <div className="category-product-price">
                      {product.price[0].price}
                      <span>AED /pack</span>
                    </div>
                  </div>
                  <div className="category-product-desc">{product.name}</div>
                  <div className="category-product-pills">
                    <div className="category-product-pill">
                      <span> Materials : </span>
                      {product.material.substring(0, 15)}
                    </div>
                    <div className="category-product-pill">
                      <span> Size: </span>
                      {product.size.substring(0, 14)}
                    </div>
                    <div className="category-product-pill">
                      <span> Colour: </span>
                      {product.color}
                    </div>
                  </div>
                  <button
                    onClick={() => {
                      navigate(
                        `/categories/product/${name}/${product?.productId}/${product?.product_type}`
                      );
                    }}
                  >
                    View Product Details
                  </button>
                </div>
              </div>
            );
          })}
        </div>
        <div className="mobile-category-products | category-products">
          {products?.map((product, index) => {
            return (
              <div key={index} className="category-product">
                <div className="category-product-mobile-flex">
                  <div className="category-product-img">
                    <img src={EcommerceIcons.Featured} alt="" />
                  </div>
                  <div className="category-product-mobile-flex-name">
                    <div className="category-product-name">
                      {product.product_type}
                    </div>
                    <div className="category-product-desc">{product.name}</div>
                    <div className="category-product-price">
                      {product.price[0].price}
                      <span>AED /pack</span>
                    </div>
                  </div>
                </div>
                <div className="category-product-pills">
                    <div className="category-product-pill">
                      <span> Materials : </span>
                      {product.material.substring(0, 15)}
                    </div>
                    <div className="category-product-pill">
                      <span> Size: </span>
                      {product.size.substring(0, 14)}
                    </div>
                    <div className="category-product-pill">
                      <span> Colour: </span>
                      {product.color}
                    </div>
                  </div>
                  <button
                    onClick={() => {
                      navigate(
                        `/categories/product/${name}/${product?.productId}/${product?.product_type}`
                      );
                    }}
                  >
                    View Product Details
                  </button>
                {/* <div className="category-product-details">
                  <div className="category-product-name-price">
                    <div className="category-product-name">
                      {product.product_type}
                    </div>
                    <div className="category-product-price">
                      {product.price[0].price}
                      <span>AED /pack</span>
                    </div>
                  </div>
                  <div className="category-product-desc">{product.name}</div>
                  <div className="category-product-pills">
                    <div className="category-product-pill">
                      <span> Materials : </span>
                      {product.material.substring(0, 15)}
                    </div>
                    <div className="category-product-pill">
                      <span> Size: </span>
                      {product.size.substring(0, 14)}
                    </div>
                    <div className="category-product-pill">
                      <span> Colour: </span>
                      {product.color}
                    </div>
                  </div>
                  <button
                    onClick={() => {
                      navigate(
                        `/categories/product/${name}/${product?.productId}/${product?.product_type}`
                      );
                    }}
                  >
                    View Product Details
                  </button>
                </div> */}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Categories;
