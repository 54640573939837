import apiCall from "../apiCall";

export const getCart = async () => {
  try {
    const user_id = JSON.parse(localStorage.getItem("ecommerce-user"))?.user_id;
    return apiCall(`/getCart?user_id=${user_id}`, "GET", null);
  } catch (error) {
    throw new Error(`Getting User Data failed: ${error.message}`);
  }
};

export const addToCart = async (formdata) => {
  try {
    const payload = {
      private_key: JSON.parse(localStorage.getItem("ecommerce-user"))?.private_key,
      quantity: formdata.quantity,
      product_id: formdata.product_id,
      pack_size: formdata.pack,
    };

    return apiCall("/addToCart", "POST", payload);
  } catch (error) {
    throw new Error(`Getting User Data failed: ${error.message}`);
  }
};

export const editCart = async (formdata) => {
  try {
    const payload = {
      quantity: formdata.quantity,
      cart_id: formdata.id,
    };

    return apiCall("/updateCart", "PUT", payload);
  } catch (error) {
    throw new Error(`Getting User Data failed: ${error.message}`);
  }
};
