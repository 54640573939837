import React, { useState, useEffect } from "react";
import EcommerceIcons from "../../assets/EcommerceIcons";
import HeaderText from "../../components/HeaderText/HeaderText";
import { getAllProducts } from "../../services/Product";

const AllCategories = ({ categories }) => {
  const [searchQuery, setSearchQuery] = useState("");
  useEffect(() => {
    document.title = "All Categories | Recall Store"; // Set the new title
    return () => {
      document.title = "Recall Store | The Sustainable Solution";
    };
  }, []);
  const [data, setData] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAllProducts();
        setData(response.products);
        console.log(response);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const filteredCategories = categories?.filter((category) =>
    category.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  return (
    <div className="recall-categories-page">
      <div className="recall-store-banner">
        <div className="recall-store-banner-content">
          <div className="recall-store-banner-breadcrumb">
            Recall Store / <span> All Categories</span>
          </div>
          <div className="recall-store-banner-search | categ">
            <h1>All categories</h1>
            <div className="recall-store-landing-section-one-search | recall-store-landing-section-one-search-category">
              <img src={EcommerceIcons.Search} alt="" />
              <input
                placeholder="Search Category"
                type="text"
                value={searchQuery}
                onChange={(event) => {
                  setSearchQuery(event.target.value);
                }}
              />
              <button>Search</button>
            </div>
          </div>
        </div>
      </div>
      <div className="recall-categories-page-content">
        <HeaderText size="large" text="All Categories" />
        <div className="recall-categories-page-content-desc">
          <h1>
            Find extensive range of product categories for your needs at Recall
          </h1>
          <p>
            If you are looking for a sustainable way to serve cold drinks, we
            have a product for you. Disposable Cold Cups that are certified by
            the Biodegradable Products Institute to compost in commercial
            compost facilities, which may not be available in all areas. Made
            from materials such as Corn Plastic or sustainably sourced paper,
            these compostable cold cups offer convenience without compromising
            your sustainability goals. Disposable Cold drink cups in bulk
            wholesale or smaller quantities at great prices.
          </p>
        </div>
        <div className="all-categories-list">
          {/* <div className="all-categories-list-item">
            <div className="all-categories-list-img">
              <img src={EcommerceIcons.RecallMerch} alt="" />
              <div className="all-categories-list-name | all-categories-list-name-mobile">
                <h1>Recall Merch. Products</h1>
              </div>
            </div>
            <div className="all-categories-list-name">
              <h1> Recall Merch Products</h1>
            </div>
          </div> */}

          {/* <div 
            className="all-categories-list-item"
          >
            <div className="all-categories-list-img">
              <img src={EcommerceIcons.RecallAccessories} alt="" />
              <div className="all-categories-list-name | all-categories-list-name-mobile">
                <h1>Recall Accessories</h1>
              </div>
            </div>
            <div className="all-categories-list-name">
              <h1> Recall Accessories</h1>
            </div>
          </div> */}

          {filteredCategories?.map((category, index) => {
            return (
              <div
                onClick={() => {
                  //   navigate(`/categories/${category.name}/${category.id}`);
                }}
                className="all-categories-list-item"
                key={index}
              >
                <div className="all-categories-list-img">
                  <img src={category.image} alt="" />
                  <div className="all-categories-list-name | all-categories-list-name-mobile">
                    <h1>{category.name} Products</h1>
                  </div>
                </div>
                <div className="all-categories-list-name">
                  <h1>{category.name} Products</h1>
                  <div
                    style={{
                      gridTemplateColumns:
                        data.length > 20 ? "repeat(4,1fr)" : "repeat(2,1fr)",
                    }}
                    className="all-categories-list-items"
                  >
                    {data
                      ?.filter((product) => product.category === category.id)
                      .map((product, index) => {
                        return (
                          <div
                            key={index}
                            className="all-categories-list-item-product"
                          >
                            <img src={EcommerceIcons.DropArrow} alt="" />
                            {product.product_type}
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AllCategories;
