import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import AuthenticationPages from "../pages/AuthenticationPages";
import Ecommerce from "../pages/Ecommerce";
import { useLocation } from "react-router-dom";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import { getCategories } from "../services/Product";
import MobileNavbar from "../components/Navbar/MobileNavbar";
import { scroller } from "react-scroll";
import { Helmet } from "react-helmet-async";

export default function Navigation() {
  const location = useLocation();
  const user = JSON.parse(localStorage.getItem("ecommerce-user"));
  const [data, setData] = useState();
  const handleClick = (section) => {
    scroller.scrollTo(section, {
      smooth: true,
      offset: -100,
      duration: 1200,
    });
  };

  // Determine whether to render the Navbar based on the current route
  const shouldRenderNavbar =
    !location.pathname.includes("/login") &&
    !location.pathname.includes("/signup");

  const routes = [
    {
      path: "/",
      exact: true,
      name: "Home",
      element: <Ecommerce.LandingPage categories={data} />,
      private: false,
    },
    {
      path: "/all-products",
      exact: true,
      name: "Home",
      element: <Ecommerce.AllProducts categories={data} />,
      private: false,
    },
    {
      path: "/categories/:name/:id",
      exact: true,
      name: "Home",
      element: <Ecommerce.Categories categories={data} />,
      private: false,
    },
    {
      path: "/subcategories/:name/:id/:productName",
      exact: true,
      name: "Home",
      element: <Ecommerce.SubCategory categories={data} />,
      private: false,
    },
    {
      path: "/categories/product/:name/:productId/:productName",
      exact: true,
      name: "Home",
      element: <Ecommerce.SingleProduct />,
      private: false,
    },
    {
      path: "/categories",
      exact: true,
      name: "Home",
      element: <Ecommerce.AllCategories categories={data} />,
      private: false,
    },
    {
      path: "/checkout",
      exact: true,
      name: "Home",
      element: <Ecommerce.Checkout />,
      private: true,
    },
    {
      path: "/profile",
      exact: true,
      name: "Home",
      element: <Ecommerce.Profile />,
      private: true,
    },
    {
      path: "/login/business",
      exact: true,
      name: "Login",
      element: <AuthenticationPages.BuisnessLogin />,
      private: false,
    },
    {
      path: "/login/individual",
      exact: true,
      name: "Login",
      element: <AuthenticationPages.IndividualLogin />,
      private: false,
    },
    {
      path: "/signup/business",
      exact: true,
      name: "Signup",
      element: <AuthenticationPages.BusinessSignup />,
      private: false,
    },
    {
      path: "/signup/individual",
      exact: true,
      name: "Signup",
      element: <AuthenticationPages.IndividualSignup />,
      private: false,
    },
    {
      path: "/payment_success",
      exact: true,
      name: "Signup",
      element: <Ecommerce.PaymentSuccess />,
      private: true,
    },
    {
      path: "/payment_failed",
      exact: true,
      name: "Signup",
      element: <Ecommerce.PaymentFailed />,
      private: true,
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getCategories();
        setData(response);
        console.log(response);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);
  return (
    <>
      {window.location.pathname.startsWith("/categories") ? null : (
        <Helmet>
          <meta
            property="og:image"
            content="https://store.recalluae.com/og.webp"
          />
        </Helmet>
      )}

      {shouldRenderNavbar && (
        <>
          <Navbar categories={data} handleClick={handleClick} />{" "}
          <MobileNavbar categories={data} />
        </>
      )}
      <Routes>
        {routes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={
              route.private && !user ? (
                <Navigate to="/login/individual" />
              ) : (
                route.element
              )
            }
          />
        ))}
      </Routes>
      {shouldRenderNavbar && <Footer handleClick={handleClick} />}
    </>
  );
}
