import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navigation from "./routes/routes";
import { HelmetProvider } from "react-helmet-async";
import { DataProvider } from "./context/DataContext";

import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(
  "pk_test_51O4IaFCteqzRMrRyZqmHg7Px91gXQ5m75tC3aUTx5hUKtTLSGcRwpPdhvTHgPvpsF0tln9AwRoTx2xxoKUgkHN3Y00pP3AAaNg"
);

function App() {
  const helmetContext = {};
  return (
    <BrowserRouter>
      <ToastContainer /> 
        <HelmetProvider context={helmetContext}>
          <DataProvider>
            <Navigation />
          </DataProvider>
        </HelmetProvider> 
    </BrowserRouter>
  );
}

export default App;
