import React from "react";
import EcommerceIcons from "../../assets/EcommerceIcons";

const HeaderText = ({ size, text, color }) => {
  let barWidth, fontSize, arrowWidth;

  if (size === "large") {
    barWidth = "12px";
    fontSize = "36px";
    arrowWidth = "30px";
  } else if (size === "medium") {
    barWidth = "8px";  
    fontSize = "22px";  
    arrowWidth = "22px";  
  } else {
    barWidth = "6px";
    fontSize = "16px";
    arrowWidth = "18px";
  }

  return (
    <div className="header-text">
      <div className="header-text-bar-flex">
        <div
          className="header-text-bar"
          style={{
            width: barWidth,
            backgroundColor: color === "white" ? "white" : "#89CFF0",
          }}
        ></div>
        <div
          className="header-text-text"
          style={{
            fontSize: fontSize,
            color: color === "white" ? "white" : "#000",
          }}
        >
          {text}
        </div>
      </div>
      <div className="header-text-arrow">
        <img
          style={{
            width: arrowWidth,
          }}
          src={
            color === "white"
              ? EcommerceIcons.TextArrowWhite
              : EcommerceIcons.TextArrow
          }
          alt=""
        />
      </div>
    </div>
  );
};

export default HeaderText;
