import React, { useState, useEffect } from "react";
import HeaderText from "../../components/HeaderText/HeaderText";
import { debounce } from "lodash";
import { getCart, editCart } from "../../services/Product/cart";
import EcommerceIcons from "../../assets/EcommerceIcons";
import { getUserStats } from "../../services/User";
import { loadStripe } from "@stripe/stripe-js";

const Checkout = () => {
  const tax = 4;
  const [data, setData] = useState();
  const [subtotal, setSubtotal] = useState();
  const [recallPoints, setRecallPoints] = useState(0);
  const [total, setTotal] = useState(0);
  const [showRedeemPoints, setShowRedeemPoints] = useState(false);

  //*  Fetch cart data and user stats
  useEffect(() => {
    const fetchData = async () => {
      try {
        //*  Fetch cart data
        const [cartResponse, userStatsResponse] = await Promise.all([
          getCart(),
          getUserStats(),
        ]);
        setData(cartResponse);

        //*  Calculate subtotal
        const initialSubtotal = cartResponse?.cart.reduce((sum, product) => {
          const price = product.product.price[0].price;
          return sum + price * product.quantity;
        }, 0);
        setSubtotal(initialSubtotal);

        //* Set recall points
        setRecallPoints(userStatsResponse.user_stats.total_points);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  //* Handle quantity change for a cart item
  const handleQuantityChange = (itemId, newQuantity) => {
    const updatedData = { ...data };
    const updatedProduct = updatedData.cart.find(
      (product) => product.id === itemId
    );
    if (updatedProduct) {
      updatedProduct.quantity = newQuantity;
      setData(updatedData);

      //* Calculate subtotal
      const updatedSubtotal = updatedData.cart.reduce((sum, product) => {
        const price = product.product.price[0].price;
        return sum + price * product.quantity;
      }, 0);
      setSubtotal(updatedSubtotal);
    }
  };

  //* Debounced function to update cart with a delay
  const debouncedUpdateCart = debounce(async (itemId, newQuantity) => {
    const payload = {
      id: itemId,
      quantity: newQuantity,
    };
    try {
      await editCart(payload);
    } catch (error) {
      console.error(error);
    }
  }, 1200);

  //* Handle redeem points checkbox change
  const handleRedeemPoints = () => {
    setShowRedeemPoints(!showRedeemPoints);
  };

  //* Calculate total amount based on redeem points and subtotal
  useEffect(() => {
    if (showRedeemPoints) {
      const updatedTotal = subtotal + tax - recallPoints;
      setTotal(updatedTotal >= 0 ? updatedTotal : 0);
    } else {
      setTotal(subtotal + tax);
    }
  }, [showRedeemPoints, subtotal, tax, recallPoints]);

  //* payment integration
  const makePayment = async () => {
    const stripe = await loadStripe(
      "pk_test_51O4IaFCteqzRMrRyZqmHg7Px91gXQ5m75tC3aUTx5hUKtTLSGcRwpPdhvTHgPvpsF0tln9AwRoTx2xxoKUgkHN3Y00pP3AAaNg"
    );

    const body = {
      products: data.cart,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    const response = await fetch(
      "http://localhost:7000/api/create-checkout-session",
      {
        method: "POST",
        headers: headers,
        body: JSON.stringify(body),
      }
    );

    const session = await response.json();

    const result = stripe.redirectToCheckout({
      sessionId: session.id,
    });

    if (result.error) {
      console.log(result.error);
    }
  };

  return (
    <div className="recall-store-checkout">
      <div className="recall-store-banner">
        <div className="recall-store-banner-content">
          <div className="recall-store-banner-breadcrumb">
            Recall Store / <span> Your Cart</span>
          </div>
          <div className="recall-store-banner-search | categ">
            <h1>Your Cart Listed Below</h1>
          </div>
        </div>
      </div>
      <div className="recall-store-checkout-content">
        <div className="recall-store-checkout-left">
          <HeaderText size="large" text="Your Cart" />

          <div className="recall-store-cart-items">
            {data?.cart.map((item) => {
              return (
                <div className="recall-addded-item">
                  <div className="recall-addded-item-img-flex">
                    <div className="recall-addded-item-img">
                      <img src={EcommerceIcons.Featured} alt="" />
                    </div>
                    <div className="recall-addded-item-info">
                      <div className="recall-addded-item-info-name">
                        {item?.product.name}
                      </div>
                      <div className="recall-addded-item-info-desc">
                        Package Size :
                        {/* {item?.price[activePack].pack
                    } */}
                      </div>
                    </div>
                  </div>
                  <div className="recall-single-product-quantity-count">
                    <div
                      onClick={() => {
                        const newQuantity = parseInt(item.quantity) - 1;
                        debouncedUpdateCart(item.id, newQuantity);
                        handleQuantityChange(item.id, newQuantity);
                      }}
                      className="recall-single-product-quantity-count-text"
                    >
                      -
                    </div>
                    <div className="recall-single-product-quantity-count-text">
                      {item.quantity}
                    </div>
                    <div
                      onClick={() => {
                        const newQuantity = parseInt(item.quantity) + 1;
                        debouncedUpdateCart(item.id, newQuantity);
                        handleQuantityChange(item.id, newQuantity);
                      }}
                      className="recall-single-product-quantity-count-text"
                    >
                      +
                    </div>
                  </div>
                  <div className="recall-addded-item-price">
                    {item.product.price[0].price} <span> AED</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="recall-store-checkout-right">
          <div className="recall-store-summary">
            <HeaderText size="medium" text="Order Summary" />
            <div className="recall-store-summary-items">
              <div className="recall-store-summary-item">
                <p>Subtotal</p>
                <p>{subtotal} AED</p>
              </div>
              <div className="recall-store-summary-item">
                <p>Tax</p>
                <p>{tax} AED</p>
              </div>
              <div className="redeem-points-summary">
                <input
                  type="checkbox"
                  checked={showRedeemPoints}
                  onChange={handleRedeemPoints}
                />
                <p>Redeem my recall points</p>
              </div>
              {showRedeemPoints && (
                <div className="order-summary-flex">
                  {/* <div className="order-summary-flex-name">Redeemed Points</div> */}
                  <p>Redeemed Points</p>
                  <p>
                    {recallPoints} <span>AED</span>
                  </p>
                </div>
              )}
              <div className="recall-store-grand-total">
                <p>Grand Total</p>
                <div className="grand-total-price">
                  {total} <span> AED</span>
                </div>
              </div>
              <button onClick={makePayment}>Checkout </button>
            </div>
          </div>
          <div className="recall-store-impact-summary">
            <div className="recall-store-impact-summary-img">
              <img src={EcommerceIcons.ProductImpact} alt="" />
            </div>
            <div className="recall-store-impact-summary-text">
              Your purchases saves 1 gallon of fuel by choosing "Plant Based"
              vs. Styrofoam™
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
