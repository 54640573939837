import Logo from "./Logo.svg";
import FooterLogo from "./FooterLogo.svg";
import LoginLogo from "./LoginLogo.svg";
import ModalLogo from "./ModalLogo.svg";
import RecallPoints from "./RecallPoints.svg";
import Icon from "./Icon.svg";
const Brand = {
  Logo,
  FooterLogo,
  Icon,
  LoginLogo,
  ModalLogo,
  RecallPoints
};

export default Brand;
