import apiCall from "../apiCall";

export const getProductsByCategory = async (category) => {
  try {
    return apiCall(`/getProduct?category_id=${category}`, "GET", null);
  } catch (error) {
    throw new Error(`Getting User Data failed: ${error.message}`);
  }
};

export const getCategories = async () => {
  try {
    return apiCall("/getAllCategories", "GET", null);
  } catch (error) {
    throw new Error(`Getting User Data failed: ${error.message}`);
  }
};

export const getProductsByproductId = async (productId) => {
  try {
    return apiCall(`/getProduct?product_id=${productId}`, "GET", null);
  } catch (error) {
    throw new Error(`Getting User Data failed: ${error.message}`);
  }
};

export const getAllProducts = async () => {
  try {
    return apiCall(`/getProduct`, "GET", null);
  } catch (error) {
    throw new Error(`Getting User Data failed: ${error.message}`);
  }
};
