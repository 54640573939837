import React, { useEffect, useState } from "react";
import EcommerceIcons from "../../assets/EcommerceIcons";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { getProductsByCategory } from "../../services/Product";
import HeaderText from "../../components/HeaderText/HeaderText";

const SubCategory = ({ categories }) => {
  const navigate = useNavigate();
  const { name } = useParams();
  const { id } = useParams();
  const { productName } = useParams();
  const [products, setProducts] = useState([]);
  const [length, setLength] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getProductsByCategory(id);
        setProducts(response?.products);
        setLength(
          response?.products?.filter(
            (product) => product.product_type === productName
          ).length
        );
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [id]);
  return (
    <>
      <div className="recall-store-banner">
        <div className="recall-store-banner-content">
          <div className="recall-store-banner-breadcrumb">
            <p
              onClick={() => {
                navigate("/");
              }}
            >
              Categories
            </p>
            <img src={EcommerceIcons.LinkArrow} alt="" />
            <p
              onClick={() => {
                navigate(-1);
              }}
            >
              {name}
            </p>
            <img src={EcommerceIcons.LinkArrow} alt="" /> <p>{productName}</p>
          </div>
          <div className="recall-store-banner-search">
            <h1>{productName}</h1>
          </div>
        </div>
      </div>
      <div className="sub-category-container">
        <div className="recall-categories-page-content-desc">
          <HeaderText size="large" text={productName} />
          <p>
            Disposable Cold Cups that are certified by the Biodegradable
            Products Institute to compost in commercial compost facilities,
            which may not be available in all areas.
          </p>
        </div>
        <div className="number-of-results"><span> {length}</span> results found in {productName} :</div>
        <div className="category-products">
          {products
            .filter((product) => product.product_type === productName)
            ?.map((product, index) => {
              return (
                <div key={index} className="category-product">
                  <div className="category-product-img">
                    <img src={EcommerceIcons.Featured} alt="" />
                  </div>
                  <div className="category-product-details">
                    {/* <div className="category-product-name-price">
                      <div className="category-product-name">
                        {product.product_type}
                      </div>
                      <div className="category-product-price">
                        {product.price[0].price}
                        <span>AED /pack</span>
                      </div>
                    </div>
                    <div className="category-product-desc">{product.name}</div> */}
                    <div className="category-product-name-desc">
                      {product.name}
                    </div>
                    <div className="category-product-price">
                        {product.price[0].price}
                        <span>AED /pack</span>
                      </div>
                    <div className="category-product-pills">
                      <div className="category-product-pill">
                        <span> Materials : </span>
                        {product.material.substring(0, 15)}
                      </div>
                      <div className="category-product-pill">
                        <span> Size: </span>
                        {product.size.substring(0, 14)}
                      </div>
                      <div className="category-product-pill">
                        <span> Colour: </span>
                        {product.color}
                      </div>
                    </div>
                    <button
                      onClick={() => {
                        navigate(
                          `/categories/product/${name}/${product?.productId}/${product?.product_type}`
                        );
                      }}
                    >
                      View Product Details
                    </button>
                  </div>
                </div>
              );
            })}
        </div>
        <div className="mobile-category-products | category-products">
          {products
            .filter((product) => product.product_type === productName)
            ?.map((product, index) => {
            return (
              <div key={index} className="category-product">
                <div className="category-product-mobile-flex">
                  <div className="category-product-img">
                    <img src={EcommerceIcons.Featured} alt="" />
                  </div>
                  <div className="category-product-mobile-flex-name">
                    <div className="category-product-name">
                      {product.product_type}
                    </div>
                    <div className="category-product-desc">{product.name}</div>
                    <div className="category-product-price">
                      {product.price[0].price}
                      <span>AED /pack</span>
                    </div>
                  </div>
                </div>
                <div className="category-product-pills">
                    <div className="category-product-pill">
                      <span> Materials : </span>
                      {product.material.substring(0, 15)}
                    </div>
                    <div className="category-product-pill">
                      <span> Size: </span>
                      {product.size.substring(0, 14)}
                    </div>
                    <div className="category-product-pill">
                      <span> Colour: </span>
                      {product.color}
                    </div>
                  </div>
                  <button
                    onClick={() => {
                      navigate(
                        `/categories/product/${name}/${product?.productId}/${product?.product_type}`
                      );
                    }}
                  >
                    View Product Details
                  </button>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default SubCategory;
