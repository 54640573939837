import LoginModal from "./LoginModal";
import SignupModal from "./SignupModal";
import CreateAccount from "./CreateAccount";
const AuthenticationModals = {
  LoginModal,
  SignupModal,
  CreateAccount,
};

export default AuthenticationModals;
