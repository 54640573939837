import React, { useState } from "react";
import AuthenticationModals from "../../modals/AuthenticationModals";
import Brand from "../../assets/brand";
import { useNavigate } from "react-router-dom";
import EcommerceIcons from "../../assets/EcommerceIcons";

import { scroller } from "react-scroll";
import Menu from "../../modals/Menu/Menu";

const MobileNavbar = ({ categories }) => {
  const navigate = useNavigate();
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [showSignupModal, setShowSignupModal] = useState(false);
  const handleClick = () => {
    setShowMenu(true);
  };
  const handleClickScroll = (section) => {
    scroller.scrollTo(section, {
      smooth: true,
      offset: -100,
      duration: 1200,
    });
  };
  return (
    <div className="recall-store-navbar-mobile">
         <Menu
        open={showMenu}
        handleClose={() => setShowMenu(false)}
        handleClick={handleClickScroll}
        setShowLoginModal={setShowLoginModal}
        setShowSignupModal={setShowSignupModal}
      />
      <AuthenticationModals.LoginModal
        open={showLoginModal}
        handleClose={() => setShowLoginModal(false)}
      />
      <AuthenticationModals.SignupModal
        open={showSignupModal}
        handleClose={() => setShowSignupModal(false)}
      />
      <div
        onClick={() => {
          navigate("/");
        }}
        className="recall-store-navbar-logo"
      >
        <img src={Brand.Logo} alt="" />
      </div>
      <div onClick={() => {handleClick()}} className="recall-mobile-nav-menu">
        <img src={EcommerceIcons.Menu} alt="" />
      </div>
    </div>
  );
};

export default MobileNavbar;
