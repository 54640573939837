import Arrow from "./Arrow.svg";
import Caro1 from "./caro.png";
import Caro2 from "./caro2.png";
import Caro3 from "./caro3.png";
import Cart from "./Cart.svg";
import DemoProuct from "./demoproduct.png";
import EcomHow1 from "./ecomhow.png";
import EcomHow2 from "./ecomhow2.png";
import EcomHow3 from "./ecomhow3.png";
import Principle1 from "./Principle1.png";
import Principle2 from "./Principle2.png";
import Principle3 from "./Principle3.png";
import Principle1Bg from "./Principle1Bg.png";
import Principle2Bg from "./Principle2bg.png";
import Principle3Bg from "./Principle3bg.png";
import ImpactRecall from "./impactrecall.png";
import Profile from "./Profile.svg";
import Return from "./Return.svg";
import Search from "./Search.svg";
import NavCart from "./NavCart.svg";
import NavProfile from "./NavProfile.svg";
import NavOrder from "./NavOrder.svg";
import Dropdown from "./Dropdown.svg";
import TextArrow from "./TextArrow.svg";
import DropArrow from "./DropArrow.svg";
import CategoryArrow from "./CategoryArrow.svg";
import ProductImpact from "./productImpact.png";
import TextArrowWhite from "./TextArrowWhite.svg";
import TextArrowBlue from "./TextArrowBlue.svg";
import Featured from "./Featured.png";
import Howto1 from "./Howto1.png";
import Howto2 from "./Howto2.png";
import Howto3 from "./Howto3.png";
import Howto4 from "./Howto4.png";
import Linkedin from "./Linkedin.svg";
import RecallPromo from "./RecallPromo.png";
import Contact from "./Contact.png";
import Facebook from "./Facebook.svg";
import Twitter from "./Twitter.svg";
import Youtube from "./Youtube.svg";
import Instagram from "./Instagram.svg";
import DropDownUser from "./DropDownUser.svg";
import Store from "./Store.svg";
import RightArrow from "./RightArrow.svg";
import Logout from "./Logout.svg";
import NotFound from "./NotFound.svg";
import Menu from "./Menu.svg";
import UserProfile from "./UserProfile.svg";
import OtherBag from "./OtherBag.svg";
import Mark from "./Mark.svg";
import RecallMerch from "./RecallMerch.png";
import RecallAccessories from "./RecallAccessories.png";
import Others from "./Others.png";
import LinkArrow from "./LinkArrow.svg";
import Filter from "./Filter.svg";
import BlackLinkArrow from "./BlackLinkArrow.svg";
import PaymentSuccess from "./PaymentSuccess.svg";
import PaymentFailed from "./PaymentFailed.svg";
const EcommerceIcons = {
  Arrow,
  Others,
  PaymentSuccess,
  PaymentFailed,
  BlackLinkArrow,
  LinkArrow,
  OtherBag,
  Howto1,
  Mark,
  RecallAccessories,
  RecallMerch,
  Howto2,
  Howto3,
  Howto4,
  Caro1,
  Filter,
  Caro2,
  Caro3,
  Menu,
  Cart,
  DemoProuct,
  EcomHow1,
  EcomHow2,
  EcomHow3,
  ImpactRecall,
  Profile,
  Return,
  Search,
  ProductImpact,
  NavCart,
  NavProfile,
  NavOrder,
  Dropdown,
  TextArrow,
  TextArrowWhite,
  DropArrow,
  CategoryArrow,
  Featured,
  RecallPromo,
  TextArrowBlue,
  Principle1Bg,
  Principle1,
  Principle2,
  Principle3,
  Principle2Bg,
  Principle3Bg,
  Facebook,
  Instagram,
  Linkedin,
  Twitter,
  Youtube,
  Contact,
  DropDownUser,
  Store,
  RightArrow,
  Logout,
  NotFound,
  UserProfile
};

export default EcommerceIcons;
