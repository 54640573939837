import React, { useState, useEffect } from "react";
import EcommerceIcons from "../../assets/EcommerceIcons";
import { getUserStats } from "../../services/User";

const MobileFilters = ({
  categories,
  filteredData,
  setFilteredData,
  filteredSizes,
  setFilteredSizes,
  filteredMaterials,
  setFilteredMaterials,
  filteredTypes,
  setFilteredTypes,
}) => {
  const [openMobileFilters, setOpenMobileFilters] = useState(false);
  const [openMobileFilter1, setOpenMobileFilter1] = useState(false);
  const [openMobileFilter2, setOpenMobileFilter2] = useState(false);
  const [openMobileFilter3, setOpenMobileFilter3] = useState(false);
  const [sizes, setsizes] = useState();
  const [materials, setMaterials] = useState();
  const [types, setTypes] = useState();
  const [stats, setStats] = useState();
  useEffect(() => {
    const sizes = [...new Set(filteredData.map((product) => product.size))];
    setsizes(sizes);

    const materials = [
      ...new Set(filteredData.map((product) => product.material)),
    ];
    setMaterials(materials);

    const types = [
      ...new Set(filteredData.map((product) => product.product_type)),
    ];
    setTypes(types);

    const fetchData2 = async () => {
      try {
        const response = await getUserStats();
        setStats(response);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData2();
  }, [filteredData]);

  const handleSizeFilter = (size) => {
    // Check if the size is already selected
    if (filteredSizes.includes(size)) {
      // Remove the size from the selected filters
      const updatedSizes = filteredSizes.filter(
        (selectedSize) => selectedSize !== size
      );
      setFilteredSizes(updatedSizes);
    } else {
      // Add the size to the selected filters
      setFilteredSizes([...filteredSizes, size]);
    }
  };
  const handleMaterialFilter = (material) => {
    if (filteredMaterials.includes(material)) {
      const updatedMaterials = filteredMaterials.filter(
        (selectedMaterial) => selectedMaterial !== material
      );
      setFilteredMaterials(updatedMaterials);
    } else {
      setFilteredMaterials([...filteredMaterials, material]);
    }
  };

  // Handle filter selections and update state for types
  const handleTypeFilter = (type) => {
    if (filteredTypes.includes(type)) {
      const updatedTypes = filteredTypes.filter(
        (selectedType) => selectedType !== type
      );
      setFilteredTypes(updatedTypes);
    } else {
      setFilteredTypes([...filteredTypes, type]);
    }
  };
  const applyFilters = () => {
    let filteredProducts = [...filteredData];

    if (filteredSizes.length > 0) {
      filteredProducts = filteredProducts.filter((product) =>
        filteredSizes.includes(product.size)
      );
    }

    if (filteredMaterials.length > 0) {
      filteredProducts = filteredProducts.filter((product) =>
        filteredMaterials.includes(product.material)
      );
    }

    if (filteredTypes.length > 0) {
      filteredProducts = filteredProducts.filter((product) =>
        filteredTypes.includes(product.product_type)
      );
    }

    // Update the state with the filtered data
    setFilteredData(filteredProducts);
  };
  return (
    <div className="mobile-filters">
      <div
        onClick={() => {
          setOpenMobileFilters(!openMobileFilters);
        }}
        className="mobile-filters-header"
      >
        <div className="mobile-filters-header-content">
          <img src={EcommerceIcons.Filter} alt="" />
          <p>Recall Filters</p>
        </div>
        <div
          className={`${
            openMobileFilters
              ? "open mobile-filters-header-icon"
              : "mobile-filters-header-icon"
          }`}
        >
          <img src={EcommerceIcons.BlackLinkArrow} alt="" />
        </div>
      </div>
      {openMobileFilters && (
        <>
          <div className="mobile-filters-list">
            <div className="mobile-filters-list-item">
              <div
                onClick={() => {
                  setOpenMobileFilter1(!openMobileFilter1);
                  setOpenMobileFilter2(false);
                  setOpenMobileFilter3(false);
                }}
                className="mobile-filters-list-header"
              >
                <p>Product Size</p>
                <img
                  className={`${openMobileFilter1 ? "open-subfilter" : ""}`}
                  src={EcommerceIcons.BlackLinkArrow}
                  alt=""
                />
              </div>
              <div className="mobile-filters-list-item-filters">
                {openMobileFilter1 && (
                  <div className="mobile-filters-category">
                    {sizes?.map((size) => {
                      return (
                        <div className="mobile-filters-category-item">
                          <input
                            type="checkbox"
                            onChange={() => handleSizeFilter(size)}
                            checked={filteredSizes.includes(size)}
                          />
                          {size}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
            <div className="mobile-filters-list-item">
              <div
                onClick={() => {
                  setOpenMobileFilter2(!openMobileFilter2);
                  setOpenMobileFilter1(false);
                  setOpenMobileFilter3(false);
                }}
                className="mobile-filters-list-header"
              >
                <p>Product Material</p>
                <img
                  className={`${openMobileFilter2 ? "open-subfilter" : ""}`}
                  src={EcommerceIcons.BlackLinkArrow}
                  alt=""
                />
              </div>
              <div className="mobile-filters-list-item-filters">
                {openMobileFilter2 && (
                  <>
                    <div className="mobile-filters-category">
                      {materials?.map((material) => {
                        return (
                          <div className="mobile-filters-category-item">
                            <input
                              type="checkbox"
                              onChange={() => handleMaterialFilter(material)}
                              checked={filteredMaterials.includes(material)}
                            />
                            {material}
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="mobile-filters-list-item">
              <div
                onClick={() => {
                  setOpenMobileFilter3(!openMobileFilter3);
                  setOpenMobileFilter1(false);
                  setOpenMobileFilter2(false);
                }}
                className="mobile-filters-list-header"
              >
                <p>Product Size</p>
                <img
                  className={`${openMobileFilter3 ? "open-subfilter" : ""}`}
                  src={EcommerceIcons.BlackLinkArrow}
                  alt=""
                />
              </div>
              <div className="mobile-filters-list-item-filters">
                {openMobileFilter3 && (
                  <>
                    <div className="mobile-filters-category">
                      {types?.map((type) => {
                        return (
                          <div className="mobile-filters-category-item">
                            <input
                              type="checkbox"
                              onChange={() => handleTypeFilter(type)}
                              checked={filteredMaterials.includes(type)}
                            />
                            {type}
                          </div>
                        );
                      })}
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="mobile-recall-points-filter">
              <input
                type="checkbox"
                //   onChange={() => handleTypeFilter(type)}
                //   checked={filteredMaterials.includes(type)}
              />
              Within My Recall Points
            </div>
          </div>
          <div className="mobile-filters-button">
            <button onClick={applyFilters}>Apply Filters</button>
            <button
              className="secondary"
              onClick={() => {
                setFilteredSizes([]);
                setFilteredMaterials([]);
                setFilteredTypes([]);
              }}
            >
              {" "}
              Reset Filters
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default MobileFilters;
