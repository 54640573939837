import React, { useRef, useEffect } from "react";
import html2canvas from "html2canvas";
import Brand from "../../assets/brand";

const DynamicImageGenerator = ({ productName, description, setImageUrl }) => {
  const imageRef = useRef();

  const generateImage = () => {
    html2canvas(imageRef.current).then((canvas) => {
      const dataURL = canvas.toDataURL("image/png");
      setImageUrl(dataURL); // Pass the data URL to the parent component
    });
  };

  useEffect(() => {
    // Automatically generate the image when the component mounts
    generateImage();
  }, []);
  // call generateImage() when page loads
  // generateImage();
  return (
    <div>
      <div className="dynamic-ogimage" ref={imageRef}>
        <h1>
          <img src={Brand.Icon} alt="" /> Recall Store | {productName}
        </h1>
        {/* <p>{description}</p> */}

        <p>
          Introducing our eco-friendly {productName} - the perfect choice for
          sustainable earth. Crafted from high-quality, recycled materials, our
          {productName} combine style with environmental responsibility. Every
          step with our clamshells is a step toward a greener, brighter future.
        </p>
        {/* Add other content */}
      </div>
      {/* <button onClick={generateImage}>Generate Image</button> */}
    </div>
  );
};

export default DynamicImageGenerator;
