import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./styles/singleproduct.css";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import EcommerceIcons from "../../assets/EcommerceIcons";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import {
  getProductsByCategory,
  getProductsByproductId,
} from "../../services/Product";
import { addToCart } from "../../services/Product/cart";
import ShowAddedItem from "../../modals/EcommerceModals/ShowAddedItem";
import HeaderText from "../../components/HeaderText/HeaderText";
import DynamicImageGenerator from "../../components/SEO/DynamicImageGenerator";
import AuthenticationModals from "../../modals/AuthenticationModals";

const SingleProduct = () => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("ecommerce-user"));
  const [quantity, setQuantity] = useState(1);
  const [imageUrl, setImageUrl] = useState("");
  const [activePack, setActivePack] = useState(0);
  const [, setRecommendations] = useState();
  const [activeTab, setActiveTab] = useState(0);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);

  const { productId } = useParams();
  const { name } = useParams();
  const { productName } = useParams();

  const handlePackClick = (index) => {
    setActivePack(index); // Update the active pack index on click
  };
  const handleClose = () => {
    setOpen(false);
  };
  const addProductCart = async (id) => {
    // console.log(id);
    console.log(data[0]?.price[activePack].size.substring(0, 3));
    const payload = {
      product_id: id,
      quantity: quantity,
      // in pack we have to take the size value of active index
      pack: parseInt(data[0]?.price[activePack].size.substring(0, 3)),
    };
    // console.log(payload);
    try {
      const response = await addToCart(payload);
      console.log(response);
    } catch (error) {
      console.error(error);
    }
  };
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getProductsByproductId(productId);
        console.log(response);
        setData(response.products);
        const product = response.products;
        fetchRecommendations(product.category);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchRecommendations = async (id) => {
      try {
        if (id) {
          const response = await getProductsByCategory(id);
          setRecommendations(response);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [productId]);
  console.log(data);

  const product = data[activePack];

  // Set the dynamic title and meta description
  const pageTitle = `${product?.product_type} | ${name} ` || "Product Name"; // Replace with a default title if the product doesn't have a title
  // const metaDescription = product?.name || "Product description goes here";

  console.log("url", imageUrl);
  useEffect(() => {
    // document.title = pageTitle;
    document.title = `${name} | ${productName}`;
    return () => {
      document.title = "Recall Store | The Sustainable Solution";
    };
  }, []);

  const [showCreateAccountModal, setShowCreateAccountModal] = useState(false);

  return (
    <div className="recall-store-single-product">
      <Helmet>
        <meta
          property="og:image"
          content={`https://store.recalluae.com/productImages/${name}.webp`}
        />
        {/* <title>{pageTitle}</title>
        <meta name="description" content={metaDescription} /> */}
      </Helmet>
      <AuthenticationModals.CreateAccount
        open={showCreateAccountModal}
        handleClose={() => setShowCreateAccountModal(false)}
      />

      {open && (
        <ShowAddedItem
          open={open}
          handleClose={handleClose}
          quantity={quantity}
          setQuantity={setQuantity}
          activePack={activePack}
          setActivePack={setActivePack}
          data={data}
        />
      )}
      <div className="recall-store-banner">
        <div className="recall-store-banner-content">
          <div className="recall-store-banner-breadcrumb">
            <p
              onClick={() => {
                navigate("/");
              }}
            >
              Categories
            </p>
            <img src={EcommerceIcons.LinkArrow} alt="" />
            <p
              onClick={() => {
                navigate(-1);
              }}
            >
              {name}
            </p>
            <img src={EcommerceIcons.LinkArrow} alt="" /> <p>{productName}</p>
          </div>
          <div className="recall-store-banner-search">
            <h1>{productName}</h1>
          </div>
        </div>
      </div>

      <div className="hide-og">
        <DynamicImageGenerator
          productName={product?.product_type}
          description="Some dynamic product description goes here"
          setImageUrl={setImageUrl}
        />
      </div>
      <div className="recall-single-product">
        <HeaderText size="large" text="Cold Cups" />
        {data?.map((product, index) => {
          return (
            <div className="recall-single-product-flex">
              <div className="recall-single-product-left">
                <div className="recall-single-product-left-img">
                  <img src={EcommerceIcons.Featured} alt="" />
                </div>
              </div>
              <div className="recall-single-product-right">
                <div className="recall-single-product-name-price">
                  <div className="recall-single-product-name">
                    {product?.product_type}
                  </div>
                  <div className="recall-single-product-price">
                    {product?.price[0].price}
                    <span>AED /pack</span>
                  </div>
                </div>
                <div className="recall-single-product-desc">
                  {product?.name}
                </div>
                <div className="recall-tabs">
                  <Tabs
                    value={activeTab}
                    onChange={handleTabChange}
                    variant="fullWidth"
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="Game Configuration Tabs"
                  >
                    <Tab label="Package Size" />
                    <Tab label="Materials" />
                    <Tab label="Return Policy" />
                  </Tabs>
                </div>

                {activeTab === 0 && (
                  <div className="single-product-size-list">
                    {product?.price.map((price, index) => {
                      return (
                        <div
                          className={`single-product-size-list-item ${
                            activePack === index ? "active" : ""
                          }`}
                          onClick={() => handlePackClick(index)}
                        >
                          <span>{price.size.substring(0, 2)} per pack</span>
                          <p>
                            {price.price} <span> AED</span>
                          </p>
                        </div>
                      );
                    })}
                  </div>
                )}
                {activeTab === 1 && (
                  <div className="single-product-material">
                    Materials: <b>{product?.material}</b>
                  </div>
                )}
                {activeTab === 2 && (
                  <div className="single-product-return-policy">
                    By accessing or using our Website, you agree to comply with
                    and be bound by the following Terms of Use ("Terms"). Please
                    read these Terms carefully before using the Website. If you
                    do not agree with these Terms, please do not use the
                    Website.
                  </div>
                )}

                <div className="recall-single-product-quantity">
                  <h3>Quantity :</h3>
                  <div className="recall-single-product-quantity-flex">
                    <div className="recall-single-product-quantity-count">
                      <div
                        onClick={() => {
                          if (quantity > 1) {
                            setQuantity(quantity - 1);
                          }
                        }}
                        className="recall-single-product-quantity-count-text"
                      >
                        -
                      </div>
                      <div className="recall-single-product-quantity-count-text">
                        {quantity}
                      </div>
                      <div
                        onClick={() => {
                          setQuantity(quantity + 1);
                        }}
                        className="recall-single-product-quantity-count-text"
                      >
                        +
                      </div>
                    </div>
                    <button
                      onClick={() => {
                        if (user) {
                          setOpen(true);
                          addProductCart(product?.id);
                        } else {
                          setShowCreateAccountModal(true);
                        }
                      }}
                    >
                      Add to Cart <img src={EcommerceIcons.TextArrow} alt="" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        <div className="recall-single-product-impact">
          <div className="recall-single-product-impact-img">
            <img src={EcommerceIcons.ProductImpact} alt="" />
          </div>
          <div className="recall-single-product-impact-text">
            <div className="recall-single-product-impact-text-bar"></div>
            <p>
              Your purchases saves <span> 1 gallon of fuel</span> by choosing{" "}
              <br />
              "Plant Based" vs. Styrofoam™
            </p>
          </div>
        </div>
        <div className="recall-recommended-products">
          <HeaderText size="large" text="Recommended Products" />
          <div className="recall-recommended-products-list"></div>
        </div>
      </div>
    </div>
  );
};

export default SingleProduct;
