import React, { useState, useEffect } from "react";
import "./navbar.css";
import EcommerceIcons from "../../assets/EcommerceIcons";
import Brand from "../../assets/brand";
import HeaderText from "../HeaderText/HeaderText";
import AuthenticationModals from "../../modals/AuthenticationModals";
import { useNavigate } from "react-router-dom";
import { getAllProducts } from "../../services/Product";
import { getCart } from "../../services/Product/cart";
import { getUserStats } from "../../services/User";

const Navbar = ({ categories, handleClick }) => {
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("ecommerce-user"));
  const [openCategoryIndex, setOpenCategoryIndex] = useState(null); // Use null to represent no open category
  const [hoveredCategoryIndex, setHoveredCategoryIndex] = useState(null); // Use null to represent no hovered category
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [openProduct, setOpenProduct] = useState();
  const [hoveredProduct, setHoveredProduct] = useState();
  const [data, setData] = useState();
  const menuItems = [
    {
      name: "Home",
      navigate: "/",
    },
    {
      name: "What's Recall",
      scroll: "whatsrecall",
      link: "https://recalluae.com",
    },
    {
      name: "Our Products",
      dropdown: true,
      categrories: categories?.map((category) => ({
        name: category.name,
        navigate: `/categories/${category.id}`, // Adjust the URL as needed
        products: data?.filter((product) => product.category === category.id),
      })),
    },
    {
      name: "Contact Us",
      scroll: "contactus",
    },
    {
      name: "Dashboard",
      link: "https://recalluae.com",
    },
  ];
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showSignupModal, setShowSignupModal] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAllProducts();
        setData(response.products);
        console.log(response);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const [stats, setStats] = useState();
  const [cartData, setCartData] = useState();

  // useEffect(() => {
  //   const fetchData2 = async () => {
  //     try {
  //       if (user) {
  //         const response = await getCart();
  //         setCartData(response.cart.length);
  //       }
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };
  //   const fetchData = async () => {
  //     try {
  //       if (user) {
  //         const response = await getUserStats();
  //         setStats(response);
  //       }
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };

  //   fetchData();
  //   fetchData2();
  // }, [user]);
  useEffect(() => {
    const fetchData = async () => {
      console.log("called");
      try {
        if (user) {
          console.log("iside");
          const response = await getUserStats();
          setStats(response);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      console.log("called");
      try {
        if (user) {
          console.log("iside");
          const response = await getCart();
          setCartData(response.cart.length);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);
  return (
    <div className="recall-store-navbar">
      <AuthenticationModals.LoginModal
        open={showLoginModal}
        handleClose={() => setShowLoginModal(false)}
      />
      <AuthenticationModals.SignupModal
        open={showSignupModal}
        handleClose={() => setShowSignupModal(false)}
      />
      <div
        onClick={() => {
          navigate("/");
        }}
        className="recall-store-navbar-logo"
      >
        <img src={Brand.Logo} alt="" />
      </div>
      <div className="recall-store-navbar-links">
        {menuItems.map((item, index) => (
          <div
            onClick={() => {
              if (item.dropdown) {
                // setOpenCategoryIndex(
                //   openCategoryIndex === index ? null : index
                // );
                // setOpenProduct(false);
              } else {
                if (item.navigate) {
                  navigate(item.navigate);
                }
                if (item.link) {
                  window.open(item.link, "_blank");
                } else {
                  if (item.scroll) {
                    handleClick(item.scroll);
                  }
                }
              }
            }}
            onMouseEnter={() => {
              if (item.dropdown) {
                setHoveredCategoryIndex(index);
              }
            }}
            onMouseLeave={() => {
              if (item.dropdown) {
                setHoveredCategoryIndex(null);
              }
            }}
            className="recall-store-navbar-links-item"
            key={index}
          >
            <p>
              {item.name}
              {item.categrories && <img src={EcommerceIcons.Dropdown} alt="" />}
            </p>
            {item.categrories &&
              (openCategoryIndex === index ||
                hoveredCategoryIndex === index) && (
                <div className="categories-dropdown">
                  <HeaderText size="small" text="Categories" />
                  <div className="categories-dropdown-list">
                    {item.categrories.map((category, categoryIndex) => (
                      <div
                        // className="recall-store-navbar-links-item-categories-item"
                        key={categoryIndex}
                        onClick={() => {
                          setOpenProduct(categoryIndex);
                        }}
                        onMouseEnter={() => {
                          setHoveredProduct(categoryIndex);
                        }}
                        onMouseLeave={() => {
                          setHoveredProduct(null);
                        }}
                        className={`recall-store-navbar-links-item-categories-item ${
                          openProduct === categoryIndex ||
                          hoveredProduct === categoryIndex
                            ? "categories-dropdown-list-products-item-active"
                            : ""
                        }`}
                      >
                        <div className="recall-store-navbar-links-item-categories-item-flex">
                          <img src={EcommerceIcons.DropArrow} alt="" />
                          {category.name}
                        </div>
                        {(openProduct === categoryIndex ||
                          hoveredProduct === categoryIndex) &&
                          category.products && (
                            <div className="categories-dropdown-list-products">
                              {category.products.map(
                                (product, productIndex) => {
                                  console.log(product);
                                  return (
                                    <div
                                      className="categories-dropdown-list-products-item"
                                      key={productIndex}
                                      onClick={() => {
                                        navigate(
                                          `/categories/product/${category.name}/${product.productId}`
                                        );
                                        setOpenProduct(false);
                                        setOpenCategoryIndex(null);
                                        setHoveredCategoryIndex(null);
                                        setHoveredProduct(null);
                                      }}
                                    >
                                      <img
                                        src={EcommerceIcons.DropArrow}
                                        alt=""
                                      />
                                      {product.product_type} (
                                      {product.name.substr(0, 15) + "..."})
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          )}
                      </div>
                    ))}
                  </div>
                </div>
              )}
          </div>
        ))}
      </div>
      <div className="recall-store-navbar-store-icons">
        <div className="recall-store-navbar-store-icons-items">
          <div
            onClick={() => {
              navigate("/checkout");
            }}
            className="recall-store-navbar-store-icons-item | cart-count-icon"
          >
            {cartData > 0 && (
              <div className="cart-count-number">{cartData}</div>
            )}

            <img src={EcommerceIcons.NavCart} alt="" />
          </div>
          <div className="recall-store-navbar-store-icons-item">
            <img src={EcommerceIcons.NavOrder} alt="" />
          </div>
        </div>
        {user ? (
          <div
            onMouseEnter={() => {
              setShowProfileModal(true);
            }}
            onMouseLeave={() => {
              setShowProfileModal(false);
            }}
            className="recall-store-navbar-store-icons-profile"
          >
            <img src={EcommerceIcons.NavProfile} alt="" />
            <div
              onClick={() => {
                // setShowProfileModal(!showProfileModal);
              }}
              className="recall-store-navbar-store-icons-profile-name"
            >
              {user.username}
              <img src={EcommerceIcons.Dropdown} alt="" />
              {showProfileModal && (
                <div className="categories-dropdown | profile-dropdown">
                  <HeaderText size="medium" text="Your Profile" />
                  <div className="profile-dropdown-content">
                    <div className="profile-dropdown-category">
                      <div className="profile-dropdown-category-profile">
                        <p>Profile</p>
                        <div className="profile-dropdown-category-profile-box">
                          <div className="profile-dropdown-category-profile-box-item | profile-box-item-username">
                            <img src={EcommerceIcons.DropDownUser} alt="" />
                            {user.username}
                          </div>
                          <div className="profile-dropdown-category-profile-box-item">
                            <img src={Brand.RecallPoints} alt="" />
                            <div className="profile-box-item-points">
                              <span> {stats?.user_stats?.total_points}</span>{" "}
                              Recall Points Available
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="profile-dropdown-category-profile-items">
                        <div className="profile-dropdown-category-profile-item">
                          <div className="profile-dropdown-category-profile">
                            <p>Navigation</p>
                          </div>
                          <div
                            onClick={() => {
                              navigate("/profile");
                            }}
                            className="profile-dropdown-category-profile-item-box"
                          >
                            <div className="profile-dropdown-category-profile-item-box-name">
                              <img src={EcommerceIcons.Store} alt="" /> Your
                              Profile
                            </div>
                            <div className="profile-dropdown-category-profile-item-box-arrow">
                              <img src={EcommerceIcons.RightArrow} alt="" />
                            </div>
                          </div>
                          <div
                            onClick={() => {
                              navigate("/checkout");
                            }}
                            className="profile-dropdown-category-profile-item-box"
                          >
                            <div className="profile-dropdown-category-profile-item-box-name">
                              <img src={EcommerceIcons.Store} alt="" />
                              Your Orders
                            </div>
                            <div className="profile-dropdown-category-profile-item-box-arrow">
                              <img src={EcommerceIcons.RightArrow} alt="" />
                            </div>
                          </div>
                          <div
                            onClick={() => {
                              navigate("/checkout");
                            }}
                            className="profile-dropdown-category-profile-item-box"
                          >
                            <div className="profile-dropdown-category-profile-item-box-name">
                              <img src={EcommerceIcons.Store} alt="" />
                              Your Cart
                            </div>
                            <div className="profile-dropdown-category-profile-item-box-arrow">
                              <img src={EcommerceIcons.RightArrow} alt="" />
                            </div>
                          </div>
                          <div
                            onClick={() => {
                              navigate("/checkout");
                            }}
                            className="profile-dropdown-category-profile-item-box"
                          >
                            <div className="profile-dropdown-category-profile-item-box-name">
                              <img src={EcommerceIcons.Store} alt="" />
                              Returns & Replacements
                            </div>
                            <div className="profile-dropdown-category-profile-item-box-arrow">
                              <img src={EcommerceIcons.RightArrow} alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        onClick={() => {
                          localStorage.removeItem("ecommerce-user");
                          // window.location.reload();
                          navigate("/");
                        }}
                        className="profile-dropdown-logout"
                      >
                        <img src={EcommerceIcons.Logout} alt="" />
                        Logout
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="recall-store-navbar-store-icons-profile">
            <img src={EcommerceIcons.NavProfile} alt="" />
            <div className="ecom-login-signup">
              <p
                onClick={() => {
                  setShowLoginModal(true);
                }}
              >
                Login <img src={EcommerceIcons.DropArrow} alt="" />
              </p>
              <p
                onClick={() => {
                  setShowSignupModal(true);
                }}
              >
                Signup <img src={EcommerceIcons.DropArrow} alt="" />
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
