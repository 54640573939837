import React from "react";
import HeaderText from "../../components/HeaderText/HeaderText";
import "./styles/profile.css";
import EcommerceIcons from "../../assets/EcommerceIcons";
import Brand from "../../assets/brand";
const Profile = () => {
  return (
    <div className="recall-store-profile">
      <div className="recall-store-banner">
        <div className="recall-store-banner-content">
          <div className="recall-store-banner-breadcrumb">
            Recall Store / <span> Your Profile</span>
          </div>
          <div className="recall-store-banner-search | categ">
            <h1>Your Profile</h1>
          </div>
        </div>
      </div>
      <div className="recall-store-profile-content">
        <HeaderText size="large" text="Your Profile" />
        <div className="recall-store-profile-flex">
          <div className="recall-store-profile-left">
            <div className="recall-store-profile-left-profile">
              <div className="recall-store-profile-left-profile-img">
                <img src={EcommerceIcons.UserProfile} alt="" />
              </div>
              <div className="recall-store-profile-left-profile-info">
                <div className="recall-store-profile-left-profile-name">
                  Dhairya{" "}
                </div>
                <div className="recall-store-profile-left-profile-email">
                  dhairyamarwah01@gmail.com
                </div>
                <div className="recall-store-profile-left-profile-points">
                  <img src={Brand.RecallPoints} alt="" />
                  873 Recall Points Available
                </div>
              </div>
            </div>
          </div>
          <div className="recall-store-profile-right">
            <div className="recall-store-profile-right-settings">
              <div className="recall-store-profile-right-settings-one">
                <div className="settings-header">Settings & Privacy</div>
                <div className="recall-store-profile-right-settings-item">
                  <div className="recall-store-profile-right-settings-item-name">
                    <img src={EcommerceIcons.Store} alt="" />
                    Privacy Policy
                  </div>
                  <div className="recall-store-profile-right-settings-item-arrow">
                    <img src={EcommerceIcons.RightArrow} alt="" />
                  </div>
                </div>
                <div className="recall-store-profile-right-settings-item">
                  <div className="recall-store-profile-right-settings-item-name">
                    <img src={EcommerceIcons.Store} alt="" />
                    Privacy Policy
                  </div>
                  <div className="recall-store-profile-right-settings-item-arrow">
                    <img src={EcommerceIcons.RightArrow} alt="" />
                  </div>
                </div>
                <div className="recall-store-profile-right-settings-item">
                  <div className="recall-store-profile-right-settings-item-name">
                    <img src={EcommerceIcons.Store} alt="" />
                    Privacy Policy
                  </div>
                  <div className="recall-store-profile-right-settings-item-arrow">
                    <img src={EcommerceIcons.RightArrow} alt="" />
                  </div>
                </div>
              </div>
              <div className="recall-store-profile-right-settings-two">
                <div className="settings-header">Settings & Privacy</div>
                <div className="recall-store-profile-right-settings-item">
                  <div className="recall-store-profile-right-settings-item-name">
                    <img src={EcommerceIcons.Store} alt="" />
                    Privacy Policy
                  </div>
                  <div className="recall-store-profile-right-settings-item-arrow">
                    <img src={EcommerceIcons.RightArrow} alt="" />
                  </div>
                </div>
                <div className="recall-store-profile-right-settings-item">
                  <div className="recall-store-profile-right-settings-item-name">
                    <img src={EcommerceIcons.Store} alt="" />
                    Privacy Policy
                  </div>
                  <div className="recall-store-profile-right-settings-item-arrow">
                    <img src={EcommerceIcons.RightArrow} alt="" />
                  </div>
                </div>
                <div className="recall-store-profile-right-settings-item">
                  <div className="recall-store-profile-right-settings-item-name">
                    <img src={EcommerceIcons.Store} alt="" />
                    Privacy Policy
                  </div>
                  <div className="recall-store-profile-right-settings-item-arrow">
                    <img src={EcommerceIcons.RightArrow} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
