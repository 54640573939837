import React, { useEffect, useState, useRef } from "react";
import EcommerceIcons from "../../assets/EcommerceIcons";
import { useLocation } from "react-router-dom";
import { getAllProducts } from "../../services/Product";
import Filters from "../../components/Filters/Filters";
import HeaderText from "../../components/HeaderText/HeaderText";

const AllProducts = ({ categories }) => {
  // setFilteredAllProducts(categories);
  const location = useLocation();
  const filtersRef = useRef(null);
  // const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isFixed, setIsFixed] = useState(false);

  const [filteredCategories, setFilteredCategories] = useState([]);
  const [filteredSizes, setFilteredSizes] = useState([]);
  const [filteredMaterials, setFilteredMaterials] = useState([]);
  const [filteredTypes, setFilteredTypes] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAllProducts();
        setProducts(response?.products);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    // Parse the search query from the URL
    const searchParams = new URLSearchParams(location.search);
    const query = searchParams.get("search");
    setSearchQuery(query || ""); // Set the search query in the component state
  }, [location.search]);

  useEffect(() => {
    document.title = "All Products | Recall Store"; // Set the new title
    return () => {
      document.title = "Recall Store | The Sustainable Solution";
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (filtersRef.current) {
        const filtersOffsetTop = filtersRef.current.getBoundingClientRect().top;

        if (!isFixed && filtersOffsetTop <= 100) {
          setIsFixed(true);
        } else if (isFixed && filtersOffsetTop > 100) {
          setIsFixed(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isFixed]);

  // Filter products based on the search query
  // const filteredProducts = products?.filter((product) =>
  //   product.name.toLowerCase().includes(searchQuery.toLowerCase())
  // );
  return (
    <div className="recall-categories-page">
      <div className="recall-store-banner">
        <div className="recall-store-banner-content">
          <div className="recall-store-banner-breadcrumb">
            Recall Store / <span> All Products</span>
          </div>
          <div className="recall-store-banner-search | categ">
            <div className="recall-store-landing-section-one-search | recall-store-landing-section-one-search-category">
              <img src={EcommerceIcons.Search} alt="" />
              <input
                placeholder="Search products"
                type="text"
                value={searchQuery}
                onChange={(event) => {
                  setSearchQuery(event.target.value);
                }}
              />
              <button>Search</button>
            </div>
          </div>
        </div>
      </div>
      {products && products?.length > 0 ? (
        <div className="recall-categories-page-content">
          <HeaderText size="large" text="All Products" />
          <div className="recall-categories-page-content-desc">
            <h1>
              Find extensive range of products for your business needs at Recall
            </h1>
            <p>
              If you are looking for a sustainable way to serve cold drinks, we
              have a product for you. Disposable Cold Cups that are certified by
              the Biodegradable Products Institute to compost in commercial
              compost facilities, which may not be available in all areas. Made
              from materials such as Corn Plastic or sustainably sourced paper,
              these compostable cold cups offer convenience without compromising
              your sustainability goals. Disposable Cold drink cups in bulk
              wholesale or smaller quantities at great prices.
            </p>
          </div>
          <div
            ref={filtersRef}
            className={`filters-wrap ${isFixed ? "sticky" : ""}`}
          >
            <Filters
              categories={categories}
              filteredData={products}
              setFilteredData={setProducts}
              isFixed={isFixed}
              filteredCategories={filteredCategories}
              setFilteredCategories={setFilteredCategories}
              filteredSizes={filteredSizes}
              setFilteredSizes={setFilteredSizes}
              filteredMaterials={filteredMaterials}
              setFilteredMaterials={setFilteredMaterials}
              filteredTypes={filteredTypes}
              setFilteredTypes={setFilteredTypes}
            />
          </div>
          <div className="category-products">
            {products?.map((product, index) => {
              return (
                <div key={index} className="category-product">
                  <div className="category-product-img">
                    <img src={EcommerceIcons.Featured} alt="" />
                  </div>
                  <div className="category-product-details">
                    <div className="category-product-name-price">
                      <div className="category-product-name">
                        {product.product_type}
                      </div>
                      <div className="category-product-price">
                        {product.price[0].price}
                        <span>AED /pack</span>
                      </div>
                    </div>
                    <div className="category-product-desc">{product.name}</div>
                    <div className="category-product-pills">
                      <div className="category-product-pill">
                        <span> Materials : </span>
                        {product.material.substring(0, 15)}
                      </div>
                      <div className="category-product-pill">
                        <span> Size: </span>
                        {product.size.substring(0, 14)}
                      </div>
                      <div className="category-product-pill">
                        <span> Colour: </span>
                        {product.color}
                      </div>
                    </div>
                    <button
                      onClick={() => {
                        // navigate(
                        //   `/categories/product/${name}/${product.productId}`
                        // );
                      }}
                    >
                      View Product Details
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <>
          <div className="product-not-found">
            <img src={EcommerceIcons.NotFound} alt="" />
            <h1>No Products found</h1>
            <p>
              Your Search didn’t match any of our products <br />
              Search something else
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default AllProducts;
