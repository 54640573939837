import React, { useState, useEffect } from "react";
import EcommerceIcons from "../../assets/EcommerceIcons";
import "./filters.css";
import { useLocation } from "react-router-dom";
import { getUserStats } from "../../services/User";
const Filters = ({
  categories,
  filteredData,
  setFilteredData,
  isFixed,
  filteredCategories,
  setFilteredCategories,
  filteredSizes,
  setFilteredSizes,
  filteredMaterials,
  setFilteredMaterials,
  filteredTypes,
  setFilteredTypes,
}) => {
  const location = useLocation();
  const [showCategory, setShowCategory] = useState(false);
  const [showSize, setShowSize] = useState(false);
  const [showMaterial, setShowMaterial] = useState(false);
  const [showType, setShowType] = useState(false);
  const [sizes, setsizes] = useState();
  const [materials, setMaterials] = useState();
  const [types, setTypes] = useState();
  const [stats, setStats] = useState();
  useEffect(() => {
    const sizes = [...new Set(filteredData.map((product) => product.size))];
    setsizes(sizes);

    const materials = [
      ...new Set(filteredData.map((product) => product.material)),
    ];
    setMaterials(materials);

    const types = [
      ...new Set(filteredData.map((product) => product.product_type)),
    ];
    setTypes(types);

    const fetchData2 = async () => {
      try {
        const response = await getUserStats();
        setStats(response);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData2();
  }, [filteredData]);

  // console.log(sizes, materials, types);
  const handleCategoryFilter = (categoryId) => {
    // Check if the category is already selected
    if (filteredCategories.includes(categoryId)) {
      // Remove the category from the selected filters
      const updatedCategories = filteredCategories.filter(
        (category) => category !== categoryId
      );
      setFilteredCategories(updatedCategories);
    } else {
      // Add the category to the selected filters
      setFilteredCategories([...filteredCategories, categoryId]);
    }
  };

  const handleSizeFilter = (size) => {
    // Check if the size is already selected
    if (filteredSizes.includes(size)) {
      // Remove the size from the selected filters
      const updatedSizes = filteredSizes.filter(
        (selectedSize) => selectedSize !== size
      );
      setFilteredSizes(updatedSizes);
    } else {
      // Add the size to the selected filters
      setFilteredSizes([...filteredSizes, size]);
    }
  };

  // Handle filter selections and update state for materials
  const handleMaterialFilter = (material) => {
    if (filteredMaterials.includes(material)) {
      const updatedMaterials = filteredMaterials.filter(
        (selectedMaterial) => selectedMaterial !== material
      );
      setFilteredMaterials(updatedMaterials);
    } else {
      setFilteredMaterials([...filteredMaterials, material]);
    }
  };

  // Handle filter selections and update state for types
  const handleTypeFilter = (type) => {
    if (filteredTypes.includes(type)) {
      const updatedTypes = filteredTypes.filter(
        (selectedType) => selectedType !== type
      );
      setFilteredTypes(updatedTypes);
    } else {
      setFilteredTypes([...filteredTypes, type]);
    }
  };
  const applyFilters = () => {
    let filteredProducts = [...filteredData];

    if (filteredCategories.length > 0) {
      filteredProducts = filteredProducts.filter((product) =>
        filteredCategories.includes(product.category)
      );
    }

    if (filteredSizes.length > 0) {
      filteredProducts = filteredProducts.filter((product) =>
        filteredSizes.includes(product.size)
      );
    }

    if (filteredMaterials.length > 0) {
      filteredProducts = filteredProducts.filter((product) =>
        filteredMaterials.includes(product.material)
      );
    }

    if (filteredTypes.length > 0) {
      filteredProducts = filteredProducts.filter((product) =>
        filteredTypes.includes(product.product_type)
      );
    }

    // Update the state with the filtered data
    setFilteredData(filteredProducts);
  };
  return (
    <div className="recall-store-filter">
      <div className="recall-store-filter-left">
        <div className="recall-store-filter-header">
          Recall Filters <img src={EcommerceIcons.TextArrow} alt="" />
        </div>
        <div className="recall-store-filter-filters">
          {location.pathname === "/all-products" && (
            <div
              // onClick={() => setShowCategory(!showCategory)}
              onMouseEnter={() => setShowCategory(true)}
              onMouseLeave={() => setShowCategory(false)}
              className="recall-store-filter-filters-item"
            >
              Category <img src={EcommerceIcons.Dropdown} alt="" />
              {showCategory && isFixed && (
                <div className="filters-category">
                  {categories?.map((category) => {
                    return (
                      <div className="filters-category-item">
                        <input
                          type="checkbox"
                          onChange={() => handleCategoryFilter(category.id)}
                          checked={filteredCategories.includes(category.id)}
                        />
                        {category.name}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          )}

          <div
            // onClick={() => setShowSize(!showSize)}
            onMouseEnter={() => setShowSize(true)}
            onMouseLeave={() => setShowSize(false)}
            className="recall-store-filter-filters-item"
          >
            {showSize && isFixed && (
              <div className="filters-category | filters-size">
                {sizes?.map((size) => {
                  return (
                    <div className="filters-category-item">
                      <input
                        type="checkbox"
                        onChange={() => handleSizeFilter(size)}
                        checked={filteredSizes.includes(size)}
                      />
                      {size}
                    </div>
                  );
                })}
              </div>
            )}
            Product Size <img src={EcommerceIcons.Dropdown} alt="" />
          </div>
          <div
            className="recall-store-filter-filters-item"
            onMouseEnter={() => setShowMaterial(true)}
            onMouseLeave={() => setShowMaterial(false)}
          >
            {showMaterial && isFixed && (
              <div className="filters-category | filters-material">
                {materials?.map((material) => {
                  return (
                    <div className="filters-category-item">
                      <input
                        type="checkbox"
                        onChange={() => handleMaterialFilter(material)}
                        checked={filteredMaterials.includes(material)}
                      />
                      {material}
                    </div>
                  );
                })}
              </div>
            )}
            Product Material <img src={EcommerceIcons.Dropdown} alt="" />
          </div>
          <div
            className="recall-store-filter-filters-item"
            onMouseEnter={() => setShowType(true)}
            onMouseLeave={() => setShowType(false)}
          >
            {showType && isFixed && (
              <div className="filters-category | filters-type">
                {types?.map((type) => {
                  return (
                    <div className="filters-category-item">
                      <input
                        type="checkbox"
                        onChange={() => handleTypeFilter(type)}
                        checked={filteredTypes.includes(type)}
                      />
                      {type}
                    </div>
                  );
                })}
              </div>
            )}
            Product Type <img src={EcommerceIcons.Dropdown} alt="" />
          </div>
          <div className="recall-store-filter-filters-item">
            <input type="checkbox" />
            Within my Recall Points
          </div>
        </div>
      </div>
      <div className="recall-store-filter-right">
        <button onClick={applyFilters}>Apply Filters</button>
      </div>
    </div>
  );
};

export default Filters;
