import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { getProductsByCategory } from "../../services/Product";
import HeaderText from "../../components/HeaderText/HeaderText";
import EcommerceIcons from "../../assets/EcommerceIcons";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  border: "none",
  borderRadius: 2,
  width: window.innerWidth > 900 ? "800px" : "90%",
  height: window.innerWidth > 900 ? "700px" : "70%",
  overflow: "scroll",
  p: 2.6,
};
const ShowAddedItem = ({
  open,
  handleClose,
  quantity,
  setQuantity,
  activePack,
  setActivePack,
  data,
}) => {
  const navigate = useNavigate();
  const [selectedPrice, setSelectedPrice] = useState(
    data[0]?.price[activePack].price
  );

  const handleQuantityChange = (value) => {
    setQuantity(value);
    const price = data[0]?.price[activePack].price;
    setSelectedPrice(price * value);
  };
  const [recommendations, setrecommendations] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getProductsByCategory(data[0]?.category);
        // console.log(response);
        setrecommendations(response?.products);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [data]);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="recall-item-added-modal">
            <div className="recall-item-added-modal-header">
              <HeaderText size="medium" text="You Just Added" />
            </div>
            <div className="recall-addded-item">
              <div className="recall-addded-item-img-flex">
                <div className="recall-addded-item-img">
                  <img src={EcommerceIcons.Featured} alt="" />
                </div>
                <div className="recall-addded-item-info">
                  <div className="recall-addded-item-info-name">
                    {data[0]?.name}
                  </div>
                  <div className="recall-addded-item-info-desc">
                    Package Size : {data[0]?.price[activePack].pack}
                  </div>
                </div>
              </div>
              <div className="recall-single-product-quantity-count">
                <div
                  onClick={() => {
                    if (quantity > 1) {
                      handleQuantityChange(quantity - 1);
                    }
                  }}
                  className="recall-single-product-quantity-count-text"
                >
                  -
                </div>
                <div className="recall-single-product-quantity-count-text">
                  {quantity}
                </div>
                <div
                  onClick={() => {
                    handleQuantityChange(quantity + 1);
                  }}
                  className="recall-single-product-quantity-count-text"
                >
                  +
                </div>
              </div>
              <div className="recall-addded-item-price">
                {selectedPrice} <span> AED</span>
              </div>
            </div>
            <div className="recall-added-buttons">
              <button
                onClick={() => {
                  navigate("/");
                }}
              >
                Continue Shopping <img src={EcommerceIcons.TextArrow} alt="" />
              </button>
              <button
                onClick={() => {
                  navigate("/checkout");
                }}
                className="secondary"
              >
                Proceed to checkout{" "}
                <img src={EcommerceIcons.TextArrowWhite} alt="" />
              </button>
            </div>
            <div className="recall-modal-recommendations-wrap">
              <HeaderText size="medium" text="You May Also Like" />
              <div className="recall-modal-recommendations">
                {recommendations?.map((product, index) => {
                  return (
                    <div key={index} className="category-product">
                      <div className="category-product-img">
                        <img src={EcommerceIcons.Featured} alt="" />
                      </div>
                      <div className="category-product-details">
                        <div className="category-product-name-price">
                          <div className="category-product-name">
                            {product.product_type}
                          </div>
                          <div className="category-product-price">
                            {product.price[0].price}
                            <span>AED /pack</span>
                          </div>
                        </div>
                        <div className="category-product-desc">
                          {product.name}
                        </div>
                        <button
                          onClick={() => {
                            //   navigate(
                            //     `/categories/product/${name}/${product.productId}`
                            //   );
                          }}
                        >
                          View Product Details
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ShowAddedItem;
