import apiCall from "../apiCall";

export const getUserStats = async () => {
  try {
    const key = JSON.parse(localStorage.getItem("ecommerce-user"));
    const payload = {
      key: key.private_key,
    };

    return apiCall(`/getStats?private_key=${payload.key}`, "GET", null);
  } catch (error) {
    throw new Error(`Getting Admin Stats failed: ${error.message}`);
  }
};
 