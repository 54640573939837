import React from "react";
import { useNavigate } from "react-router-dom";
import EcommerceIcons from "../../assets/EcommerceIcons";

const PaymentFailed = () => {
  const navigate = useNavigate();
  return (
    <div className="payment-status-page">
      <div className="recall-store-banner | payment-failed">
        <div className="recall-store-banner-content">
          <div className="recall-store-banner-breadcrumb">
            <p
              onClick={() => {
                navigate("/");
              }}
            >
              {" "}
              Recall Store
            </p>{" "}
            <img src={EcommerceIcons.LinkArrow} alt="" /> <p>Payment Status</p>
          </div>
          <div className="recall-store-banner-search">
            <h1>Payment Failed</h1>
          </div>
        </div>
      </div>
      <div className="product-not-found  | payment-status-img">
        <img src={EcommerceIcons.PaymentFailed} alt="" />
        <h1>Your Order Could Not be placed right now</h1>
        <p>Please try again in some time</p>
      </div>
    </div>
  );
};

export default PaymentFailed;
