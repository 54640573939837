import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Brand from "../../assets/brand"; 
import LoginModal from "./LoginModal";
import SignupModal from "./SignupModal";
import "./modal.css"
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  outline: "none",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  border: "none",
  borderRadius: 2,
  width: "500px",
  p: 3,
};
const CreateAccount = ({ open, handleClose }) => {
    console.log("open prop:", open);

  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showSignupModal, setShowSignupModal] = useState(false);
  return (
    <>
    <LoginModal
          open={showLoginModal}
          handleClose={() => setShowLoginModal(false)}
        />
        <SignupModal
          open={showSignupModal}
          handleClose={() => setShowSignupModal(false)}
        />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        
        <Box sx={style}>
          <div className="create-account-modal">
            <img src={Brand.Logo} alt="" />
            <h1>Create an account</h1>
            <p>You need to sign up to Recall to access this feature</p>
            <div className="buttons">
              <button
                onClick={() => {
                  setShowSignupModal(true);
                }}
              >
                Signup for Recall
              </button>
              <p>
                Already a Recall User?{" "}
                <span
                  onClick={() => {
                    setShowLoginModal(true);
                  }}
                >
                  Login
                </span>{" "}
              </p>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default CreateAccount;
