import Categories from "./Categories";
import Checkout from "./Checkout";
import LandingPage from "./LandingPage";
import SingleProduct from "./SingleProduct";
import AllProducts from "./AllProducts";
import Profile from "./Profile";
import AllCategories from "./AllCategories";
import SubCategory from "./SubCategory";
import PaymentSuccess from "./PaymentSuccess";
import PaymentFailed from "./PaymentFailed";
const Ecommerce = {
  Categories,
  Checkout,
  AllCategories,
  LandingPage,
  SingleProduct,
  AllProducts,
  Profile,
  SubCategory,
  PaymentSuccess,
  PaymentFailed,
};

export default Ecommerce;
